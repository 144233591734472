<template>
  <div>
    <input
      v-model="value"
      :type="type"
      class="h-field w-full border p-3 placeholder:text-g7-blue/50 focus:outline-none focus:ring focus:ring-g7-blue"
      :class="classes + (hasErrors ? ' border-red-500 text-red-300' : '')"
      :placeholder="placeholder"
      :name="name"
      :max="max"
      :min="min"
      :form="form"
      :checked="checked"
      :disabled="disabled"
      :data-cy="dataCy"
      :autocomplete="autocomplete"
      @input="
        emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    />
    <ErrorMessage v-if="displayError" />
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import type { AnySchema } from "yup/lib/schema";
import type { AutocompleteType, InputType } from "~~/types/form";

interface Props {
  placeholder?: string;
  type: InputType;
  modelValue?: string | number;
  name?: string;
  required?: boolean;
  max?: number;
  min?: number;
  validation?: string | AnySchema | object;
  classes?: string;
  form?: string;
  checked?: boolean;
  disabled?: boolean;
  showError?: boolean;
  dataCy?: string;
  autocomplete?: AutocompleteType;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: undefined,
  modelValue: undefined,
  name: undefined,
  min: undefined,
  max: undefined,
  type: "text",
  validation: undefined,
  classes: undefined,
  form: undefined,
  checked: false,
  disabled: false,
  showError: true,
  dataCy: undefined,
  autocomplete: "off",
});

const { value, meta } = useField(
  props.name ?? "to-do-name-input",
  props.validation,
  { syncVModel: true }
);
const hasErrors = computed(() => !meta.valid && meta.touched);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const displayError = computed(() => hasErrors.value && props.showError);
</script>
